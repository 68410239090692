import { computed, type MaybeRef, toValue, type ComputedRef } from 'vue'
import { client } from '@/plugins/trpc'
import { useQuery } from '@/composables/vue-query-with-magic'
import { EntityViewEnum } from '@mindfuel/server/src/common/config'
import { DataProductTypeEnum } from '@prisma/client'

export const useDetailAttributes = (type?: MaybeRef<EntityViewEnum>) => {
    const {
        data: detailAttributes,
        isFetching,
        isLoading,
        isRefetching,
        isPending,
    } = useQuery({
        queryKey: ['listAllDetailAttributes'],
        queryFn: () => client.detailAttributes.list.query(),
        initialData: [],
        queries: ['DetailAttribute', 'DetailAttributeValue', 'DetailAttributeEnabledFor'],
    })

    const allOrFilteredByEntityType = computed(() => {
        if (toValue(type) === EntityViewEnum.DataProduct) {
            return (
                detailAttributes.value
                    .filter((attr) => attr.entityType !== EntityViewEnum.Opportunity)
                    //unique by attribute id
                    .filter(
                        (attr, index, self) => self.findIndex((t) => t.attribute.id === attr.attribute.id) === index
                    )
                    .map(({ attribute }) => attribute)
            )
        }
        return detailAttributes.value
            .filter((attr) => !type || attr.entityType === toValue(type))
            .map(({ attribute }) => attribute)
            .filter((attr, index, self) => self.findIndex((t) => t.id === attr.id) === index)
    })

    function dataProductTypesEnabledForDetailAttribute(detailAttributeId?: ComputedRef<string | undefined>) {
        return computed(() =>
            detailAttributes.value
                .filter((attr) => attr.attribute.id === detailAttributeId?.value)
                .filter((attr) => attr.entityType !== EntityViewEnum.Opportunity)
                .map(({ entityType }) => entityType as DataProductTypeEnum)
        )
    }

    return {
        detailAttributes: allOrFilteredByEntityType,
        dataProductTypesEnabledForDetailAttribute,
        isFetching,
        isLoading,
        isRefetching,
        isPending,
    }
}
