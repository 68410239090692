import type { ServerOptions } from './types'
import { EntityTypeEnum, DataProductTypeEnum } from '@prisma/client'
export const sharedConfig: Pick<ServerOptions, 'trpcPrefix'> = {
    trpcPrefix: '/trpc',
}

export const MAX_LENGTH = {
    /** 255 characters */
    TITLE: 255,
    /** 100 characters */
    SHORT_TITLE: 100,
    /** 2000 characters */
    DESCRIPTION: 2000,
    /** 5000 characters */
    COMMENT: 5000,
    /** 300 characters */
    NAME: 300,
    /** 50 characters */
    MEDIUM_LABEL: 50,
    /** 30 characters */
    SHORT_LABEL: 30,
    /** 20 characters */
    ATTRIBUTE: 20,
    /** 10 characters */
    SCALE: 10,
}

// https://stackoverflow.com/a/57225494
export const MAX_SAFE_NUMBER = {
    DECIMAL_SCALE_2: 70368744177663.99,
    INTEGER_IN_DECIMAL_SCALE_2: 70368744177663,
}

export const EntityViewEnum = { ...EntityTypeEnum, DataProduct: 'DataProduct' } as const
export type EntityViewEnum = keyof typeof EntityViewEnum

export const EntityViewToViewName = {
    Opportunity: 'opportunities',
    DataProduct: 'data-products',
    SmartProduct: 'smart-products',
    AnalyticsProduct: 'analytics-products',
    DataAsset: 'data-assets',
    TechProduct: 'tech-products',
} as const satisfies Record<EntityViewEnum, string>

export const DataProductTypeStringToEnum = {
    'Smart Product': DataProductTypeEnum.SmartProduct,
    'Analytics Product': DataProductTypeEnum.AnalyticsProduct,
    'Data Asset': DataProductTypeEnum.DataAsset,
    'Tech Product': DataProductTypeEnum.TechProduct,
} as const satisfies Record<string, DataProductTypeEnum>

export const OwnerFeatureToggle = {
    copilot: 'copilot',
    demo: 'demo',
} as const

export const AdminFeatureToggle = {
    segments: 'segments',
} as const

export const FeatureToggle = {
    ...OwnerFeatureToggle,
    ...AdminFeatureToggle,
} as const

export type FeatureToggle = (typeof FeatureToggle)[keyof typeof FeatureToggle]
export type OwnerFeatureToggle = (typeof OwnerFeatureToggle)[keyof typeof OwnerFeatureToggle]
export type AdminFeatureToggle = (typeof AdminFeatureToggle)[keyof typeof AdminFeatureToggle]

export type FeatureToggleOptions = {
    visibleOnOrganizationsManagement: boolean
    isOrganizationControlled: boolean
    defaults: {
        isEnabled: boolean
    }
}

export const featureToggles = {
    copilot: {
        visibleOnOrganizationsManagement: true,
        isOrganizationControlled: false,
        defaults: {
            isEnabled: false,
        },
    },
    demo: {
        visibleOnOrganizationsManagement: true,
        isOrganizationControlled: false,
        defaults: {
            isEnabled: false,
        },
    },
    segments: {
        visibleOnOrganizationsManagement: false,
        isOrganizationControlled: true,
        defaults: {
            isEnabled: false,
        },
    },
} as const satisfies Record<FeatureToggle, FeatureToggleOptions>
