<script lang="ts" setup>
import { computed } from 'vue'
import type { Person } from '@prisma/client'

import { type SelectorItem } from '@/composables/selector'

import BaseIcon from './BaseIcon.vue'
import DAvatar from './DAvatar.vue'
import DButton, { type DButtonType } from './DButton.vue'
import DSelectorGeneric from './DSelectorGeneric.vue'

const NOT_SELECTED = 'not-selected'

const props = withDefaults(
    defineProps<{
        modelValue?: string
        people?: Pick<Person, 'id' | 'name' | 'photoUrl'>[]
        placeholder?: string
        notSelectedLabel?: string
        filterPlaceholder?: string
        buttonType?: DButtonType
        testid?: string
    }>(),
    {
        modelValue: NOT_SELECTED,
        buttonType: 'ghost',
        testid: 'd-selector-person',
    }
)
const emit = defineEmits<{
    (name: 'update:modelValue', value?: string): void
    (name: 'select', value: string): void
    (name: 'unselect'): void
}>()

const options = computed(() => {
    const mappedItems =
        props.people?.map((person) => ({
            id: person.id,
            label: person.name,
            avatar: {
                id: person.id,
                photoUrl: person.photoUrl,
            },
        })) ?? []
    return mappedItems as SelectorItem[]
})

const showPlaceholder = computed(() => !!props.placeholder && props.modelValue === NOT_SELECTED)
const selected = computed(() => options.value.filter(({ id }) => id === props.modelValue))

function onModelValueUpdate(items: SelectorItem[]) {
    emit('update:modelValue', items[0]?.id === NOT_SELECTED ? undefined : items[0]?.id)
}
</script>

<template>
    <DSelectorGeneric
        :model-value="selected"
        :options="options"
        max-width="sm"
        :unselect-item-label="notSelectedLabel"
        :filter-placeholder="filterPlaceholder"
        :no-items-label="$t('components.SelectorItem.noPersonsFound')"
        :testid
        @select-item="emit('select', $event)"
        @unselect-item="emit('unselect')"
        @update:model-value="onModelValueUpdate"
    >
        <slot>
            <DButton :type="buttonType ?? 'ghost'" :data-testid="testid + '-button'">
                <template v-if="showPlaceholder">
                    <BaseIcon icon="line-dashed" class="text-slate-500" size="md" />
                    <span class="truncate">{{ placeholder }}</span>
                </template>
                <template v-else>
                    <DAvatar v-if="selected[0]?.avatar?.id" :users="[selected[0]?.avatar]" size="sm" />
                    <span class="truncate">{{ selected[0]?.label }}</span>
                </template>
            </DButton>
        </slot>
    </DSelectorGeneric>
</template>
