<script lang="ts" setup>
import { computed } from 'vue'

import type { SelectorItem } from '@/composables/selector'

import BaseIcon from './BaseIcon.vue'
import DButton, { type DButtonType } from './DButton.vue'
import DSelectorGeneric from './DSelectorGeneric.vue'

const NOT_SELECTED = 'not-selected'

const props = withDefaults(
    defineProps<{
        modelValue?: string
        teams?: { id: string; label: string }[]
        placeholder?: string
        unselectItemLabel?: string
        unselectItemIcon?: string
        filterPlaceholder?: string
        type?: 'data' | 'business'
        buttonType?: DButtonType
        testid?: string
    }>(),
    {
        modelValue: NOT_SELECTED,
        type: 'data',
        testid: 'd-selector-team',
    }
)

const emit = defineEmits<{
    (name: 'update:modelValue', value?: string): void
    (name: 'select', value?: string): void
    (name: 'unselect'): void
}>()

const icon = computed(() => (props.type === 'data' ? 'users-group' : 'building-skyscraper'))
const menuItems = computed(() => {
    const mappedItems =
        props.teams?.map((team) => ({
            id: team.id,
            label: team.label,
            icon: icon.value,
        })) ?? []

    return mappedItems as SelectorItem[]
})
const showPlaceholder = computed(() => !!props.placeholder && props.modelValue === NOT_SELECTED)
const selected = computed(() => menuItems.value.filter((item) => item.id === props.modelValue))

function onModelValueUpdate(value: SelectorItem[]) {
    emit('update:modelValue', value[0]?.id === NOT_SELECTED ? undefined : value[0]?.id)
}
</script>

<template>
    <DSelectorGeneric
        :model-value="selected"
        :options="menuItems"
        max-width="sm"
        :filter-placeholder="filterPlaceholder"
        :unselect-item-label="unselectItemLabel"
        :unselect-item-icon="unselectItemIcon"
        @select-item="emit('select', $event)"
        @unselect-item="emit('unselect')"
        @update:model-value="onModelValueUpdate"
    >
        <DButton :type="buttonType ?? 'ghost'" :data-testid="testid">
            <template v-if="showPlaceholder">
                <BaseIcon :icon="icon" class="text-slate-500" size="md" />
                <span class="truncate">{{ placeholder }}</span>
            </template>
            <template v-else>
                <BaseIcon v-if="selected[0]?.icon" :icon="selected[0]?.icon" class="text-slate-500" size="md" />
                <span class="truncate">{{ selected[0]?.label }}</span>
            </template>
        </DButton>
    </DSelectorGeneric>
</template>
