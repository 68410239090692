<script lang="ts" setup>
import { computed } from 'vue'
import { watchOnce } from '@vueuse/core'
import { useField } from 'vee-validate'
import { PhaseCategorySetEnum, OpportunityPriorityEnum, DataProductTypeEnum } from '@prisma/client'

import { usePhases } from '@/composables/data/phase'
import { usePersons } from '@/composables/data/person'
import { useProfileQueries } from '@/queries/profile'
import { useKeywords } from '@/composables/data/keyword'
import { useTeams } from '@/composables/data/team'
import { useDetailAttributes } from '@/composables/data/detail-attributes'
import { useDataProductsEnabledForDetailProperty } from '@/composables/data/data-product-type'
import { EntityTypeEnumRefactored } from '@/composables/selector'

import DSelectorPriority from '@/components/DSelectorPriority.vue'
import DSelectorPerson from '@/components/DSelectorPerson.vue'
import DSelectorEntityPhase from '@/components/DSelectorEntityPhase.vue'
import DSelectorDataProductType from '@/components/DSelectorDataProductType.vue'
import DSelectorKeyword from '../DSelectorKeyword.vue'
import DSelectorTeam from '../DSelectorTeam.vue'
import DSelectorPeople from '../DSelectorPeople.vue'
import DSelectorDetailProperty from '../DSelectorDetailProperty.vue'
import { useMetricTypes } from '@/composables/data/metricType'
import DSelectorMetric from '@/modules/opportunity/components/DSelectorMetric.vue'
import DSelectorBusinessGoal from '@/modules/opportunity/components/DSelectorBusinessGoal.vue'
import { useBusinessGoals } from '@/composables/data/businessGoal'

const props = defineProps<{
    entityType: EntityTypeEnumRefactored
}>()
const isOpportunity = computed(() => props.entityType === EntityTypeEnumRefactored.Opportunity)

const { get: getCurrentUser } = useProfileQueries()

const { phases: phasesOpportunity, defaultPhase: defaultPhaseOpportunity } = usePhases(
    PhaseCategorySetEnum.OpportunityPhaseCategoryEnum
)
const { phases: phasesDataProduct, defaultPhase: defaultPhaseDataProduct } = usePhases(
    PhaseCategorySetEnum.ProductPhaseCategoryEnum
)

const { nonExternalPersons, allPersons } = usePersons()
const { data: currentUser } = getCurrentUser()

const { keywords } = useKeywords()
const { teams: businessTeams } = useTeams('business')
const { teams: dataTeams } = useTeams('data')
const { detailAttributes } = useDetailAttributes()
const { metricTypes } = useMetricTypes()
const { businessGoals } = useBusinessGoals()

const attributeId = computed(() => detailAttributeId.value)
const { enabledProductsItems } = useDataProductsEnabledForDetailProperty(attributeId)

const hasLoadedDefaults = computed(
    () => !!defaultPhaseOpportunity.value && !!defaultPhaseDataProduct.value && !!currentUser.value
)

const { value: dataProductType } = useField<DataProductTypeEnum>('dataProductType')
const { value: phaseId, setValue: setPhaseId } = useField<string>('phaseId')
const { value: priority } = useField<OpportunityPriorityEnum>('priority')
const { value: ownerId, setValue: setOwnerId } = useField<string>('ownerId')
const { value: keywordIds } = useField<string[] | undefined>('keywordIds')
const { value: businessTeamId, setValue: setBusinessTeamId } = useField<string | null | undefined>('businessTeamId')
const { value: dataTeamId, setValue: setDateTeamId } = useField<string | null | undefined>('dataTeamId')
const { value: detailAttributeId } = useField<string | undefined>('detailAttributes.0.id')
const { value: detailAttributeValueIds } = useField<string[] | undefined>('detailAttributes.0.valueIds')
const { value: metricIds } = useField<string[] | undefined>('metricIds')
const { value: businessGoalIds } = useField<string[] | undefined>('businessGoalIds')
const { value: stakeholderIds } = useField<string[] | undefined>('stakeholderIds')

const detailAttribute = computed(() => detailAttributes.value.find((da) => da.id === detailAttributeId.value))

function resetFields() {
    if (hasLoadedDefaults.value) {
        _resetFields()
        return
    }

    watchOnce(hasLoadedDefaults, _resetFields)
}

function _resetFields() {
    const isOpportunity = !dataProductType.value
    const isDataProduct = !isOpportunity

    if (isOpportunity && !phaseId.value) setPhaseId(defaultPhaseOpportunity.value.id)
    if (isDataProduct && !phaseId.value) setPhaseId(defaultPhaseDataProduct.value.id)
    if (!ownerId.value) setOwnerId(currentUser.value!.id)
}

defineExpose({ resetFields })
</script>
<template>
    <div v-if="hasLoadedDefaults" class="flex flex-wrap gap-1">
        <DSelectorEntityPhase v-if="isOpportunity" v-model="phaseId" :phases="phasesOpportunity" button-type="common" />
        <DSelectorEntityPhase v-else v-model="phaseId" :phases="phasesDataProduct" button-type="common" />

        <DSelectorPriority
            v-if="isOpportunity"
            v-model="priority"
            :placeholder="$t(`components.PrioritySelector.label`)"
            button-type="common"
        />
        <DSelectorPriority
            v-else
            v-model="priority"
            :placeholder="$t(`components.PrioritySelector.label`)"
            type="product"
            button-type="common"
        />

        <DSelectorPerson
            v-model="ownerId"
            :people="nonExternalPersons"
            :placeholder="$t('components.SelectorPerson.owner')"
            :not-selected-label="$t('components.SelectorPerson.noOwner')"
            button-type="common"
        />

        <DSelectorDataProductType
            v-if="!isOpportunity"
            v-model="dataProductType"
            :enabled-products-items="enabledProductsItems"
            button-type="common"
        />

        <DSelectorKeyword
            v-if="keywordIds"
            v-model="keywordIds"
            :keywords
            button-type="common"
            :placeholder="$t('keyword')"
        />

        <DSelectorTeam
            v-if="businessTeamId !== undefined"
            :model-value="businessTeamId ?? undefined"
            :teams="businessTeams"
            :unselect-item-label="$t('notSet')"
            :placeholder="$t('businessTeam')"
            button-type="common"
            type="business"
            @update:model-value="setBusinessTeamId($event ?? null)"
        />

        <DSelectorTeam
            v-if="dataTeamId !== undefined"
            :model-value="dataTeamId ?? undefined"
            :teams="dataTeams"
            :unselect-item-label="$t('notSet')"
            :placeholder="$t('dataProductTeam')"
            button-type="common"
            @update:model-value="setDateTeamId($event ?? null)"
        />

        <DSelectorPeople
            v-if="stakeholderIds"
            v-model="stakeholderIds"
            :people="allPersons"
            button-type="common"
            :placeholder="$t('stakeholders')"
        />

        <DSelectorDetailProperty
            v-if="detailAttribute"
            v-model="detailAttributeValueIds"
            :detail-attribute="detailAttribute"
            button-type="common"
        />

        <DSelectorBusinessGoal
            v-if="businessGoalIds"
            v-model="businessGoalIds"
            :business-goals="businessGoals"
            :placeholder="$t('businessGoals')"
            button-type="common"
        />

        <DSelectorMetric
            v-if="metricIds"
            v-model="metricIds"
            :metrics="metricTypes"
            :placeholder="$t('metrics')"
            button-type="common"
        />
    </div>
</template>
