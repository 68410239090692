<script lang="ts" setup>
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'

import { useAuthStore } from '@/stores/authStore'
import { marker } from '@/plugins/marker'
import { mainNavigation, type SideNavItem } from '@/composables/router'

import type { DMenuItem } from './DActionMenu.vue'
import BaseIcon from './BaseIcon.vue'
import DAvatar from './DAvatar.vue'
import DActionMenu from './DActionMenu.vue'
import DSideNavItem from './DSideNavItem.vue'
import CreateEntity from '@/components/create/CreateEntity.vue'
import HelpkitButton from '@/components/HelpkitButton.vue'

defineProps<{
    userId?: string
    user: { photoUrl?: string; organization?: { displayName?: string }; teamId?: string }
    unreadInboxCount?: number
    companyLogo?: string
}>()

const router = useRouter()
const { t } = useI18n()

const { isAdmin, mappedUser } = useAuthStore()
const menuItems: DMenuItem[] = [
    {
        label: t('sideNav.contextMenu.myProfile'),
        icon: 'user-circle',
        command: () => router.push('/profile'),
    },
    {
        label: t('sideNav.contextMenu.settings'),
        icon: 'adjustments',
        visible: isAdmin,
        command: () => router.push('/settings'),
    },
    {
        separator: true,
    },
    {
        label: t('sideNav.contextMenu.logout'),
        icon: 'door-exit',
        command: () => router.push('/logout'),
    },
]

const isActiveRoute = (item: SideNavItem) => {
    const currentPath = router.currentRoute.value.path
    const { to, childRoute, shouldParentBeInactive } = item

    if (!childRoute || shouldParentBeInactive) {
        return to === currentPath
    }

    return currentPath.startsWith(to) || currentPath.startsWith(childRoute)
}
</script>

<template>
    <div
        role="navigation"
        class="flex h-full w-main-nav flex-col justify-between gap-y-5 border border-slate-200 bg-white py-2 pl-4 pr-3 shadow-sm"
    >
        <div id="side-nav-top" class="flex grow flex-col gap-y-5">
            <div class="flex h-6 items-center justify-between">
                <router-link to="/" class="flex cursor-pointer items-center gap-x-2">
                    <img class="size-5" :src="companyLogo || '/Logo.svg'" />
                    <span class="text-small-medium text-slate-700">{{ user.organization?.displayName }}</span>
                </router-link>

                <DActionMenu :menu-items="menuItems">
                    <DAvatar :users="[{ id: userId, photoUrl: user.photoUrl }]" data-testid="nav-avatar" />
                </DActionMenu>
            </div>
            <div v-if="user && userId" class="flex w-full items-center justify-between gap-x-1">
                <CreateEntity class="w-full" />
            </div>

            <div class="space-y-5">
                <div v-for="item in mainNavigation" :key="item.id" class="flex flex-col gap-y-3">
                    <div class="flex items-center justify-between">
                        <DSideNavItem
                            :item="item"
                            :active="isActiveRoute(item)"
                            :badge="item.id === 'inbox' ? unreadInboxCount : undefined"
                        />
                    </div>
                    <div v-if="item.childNodes?.length" class="border-l-2 border-slate-300 space-y-2.5 ml-2 pl-3.5">
                        <DSideNavItem
                            v-for="child in item.childNodes"
                            :key="child.id"
                            :item="child"
                            :active="isActiveRoute(child)"
                            :testid="`side-nav-item-${child.id}`"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div id="side-nav-bottom" class="mb-4 flex flex-col gap-y-3">
            <HelpkitButton />

            <a
                :href="`/academy/your-first-touchpoints/oqx67kWa5WiuHxp1LyQkTL/get-a-quick-delight-walkthrough/fvk4JD9uJ9uVdNuiddGbka?auth0Id=${mappedUser?.auth0Id}&redirect=true`"
                target="_blank"
                rel="noopener noreferrer"
                class="flex h-6 items-center gap-x-1.5 rounded-sm text-slate-700 hover:cursor-pointer hover:bg-slate-100 hover:outline hover:outline-4 hover:outline-slate-100"
                data-testid="product-tour-link"
            >
                <BaseIcon icon="wand" class="text-slate-500" />
                <span class="text-small-medium">{{ $t('sideNav.productTour.title') }}</span>
            </a>

            <a
                :href="`/academy?auth0Id=${mappedUser?.auth0Id}&redirect=true`"
                target="_blank"
                rel="noopener noreferrer"
                class="flex h-6 items-center gap-x-1.5 rounded-sm text-slate-700 hover:cursor-pointer hover:bg-slate-100 hover:outline hover:outline-4 hover:outline-slate-100"
                data-testid="academy-link"
            >
                <BaseIcon icon="books" class="text-slate-500" />
                <span class="text-small-medium">{{ $t('sideNav.academy.title') }}</span>
            </a>

            <div
                class="flex h-6 items-center gap-x-1.5 rounded-sm bg-white text-slate-700 hover:outline hover:outline-4 hover:cursor-pointer hover:bg-slate-100 hover:outline-slate-100"
                @click="marker.capture('fullscreen')"
            >
                <BaseIcon icon="bug" class="text-slate-500" />
                <span class="text-small-medium">{{ $t('reportBug') }}</span>
            </div>
        </div>
    </div>
</template>
