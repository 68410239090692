<script lang="ts" setup>
import { computed } from 'vue'
import { type RouteLocationRaw } from 'vue-router'

import type { Size } from '@/composables/sizing'
import Tag, { type TagPassThroughOptions } from 'primevue/tag'
import BaseIcon from './BaseIcon.vue'
import type { PassThrough } from 'primevue/ts-helpers'

export interface TagProps {
    label?: string | number
    size?: Size
    iconLeft?: string
    iconRight?: string
    borderStyle?: 'rounded' | 'square'
    font?: 'primary' | 'secondary'
    to?: RouteLocationRaw
    readonly?: boolean
    labelColor?: string
    iconColor?: string
    bgColor?: string
}

const props = withDefaults(defineProps<TagProps>(), {
    size: 'md',
    borderStyle: 'rounded',
    font: 'primary',
    readonly: false,
    to: '',
})

const tagPT = computed<PassThrough<TagPassThroughOptions>>(() => {
    return {
        root: {
            class: [
                'flex gap-1 w-fit items-center text-slate-700',
                props.bgColor ?? 'bg-slate-100',
                props.to ? 'cursor-pointer' : 'cursor-default',
                {
                    'cursor-pointer hover:brightness-95': !props.readonly,
                },
                {
                    sm: 'text-xs px-1 h-4',
                    md: 'text-sm px-2 h-5',
                    lg: 'text-base px-3 h-7',
                }[props.size],
                {
                    rounded: 'rounded-full',
                    square: 'rounded-sm',
                }[props.borderStyle],
                {
                    primary: 'font-sans',
                    secondary: 'font-secondary',
                }[props.font],
            ],
        },
    }
})
</script>

<template>
    <Tag unstyled :pt="tagPT">
        <component :is="to ? 'router-link' : 'div'" :to="to" class="flex items-center gap-1 truncate">
            <slot name="before">
                <BaseIcon v-if="iconLeft" :icon="iconLeft" :size="size" :class="iconColor" />
            </slot>
            <span class="truncate" :class="labelColor ?? 'text-slate-700'">
                <slot>{{ label }}</slot>
            </span>
            <slot name="after">
                <BaseIcon v-if="iconRight" :icon="iconRight" :size="size" :class="iconColor" />
            </slot>
        </component>
    </Tag>
</template>
